import React , {useEffect , useState} from "react";
import { Col, Row, Table } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../utils/columnconverter";
import aictia from "../../../const/api";

const PersonDetails = (props) => {
  const mainUrl = "Account/Individual";
  const { t } = useTranslation();
  const [purchases , setPurchases] = useState({});
  const [data , setData] = useState([]);

  useEffect(()=>{
    const getData = async () => {
        try {
            const res = await aictia.get(mainUrl);
            console.log(res);
            const arr = res.data.filter((elem) => elem.id === Number(props.id));
            console.log(arr);
            setData([
                {
                    fullName: arr[0].person.fullName,
                    gmail: arr[0].person.gmail,
                    gender: arr[0].person.gender,
                    specialty: arr[0].person.specialty,
                    packet: arr[0].packetOfPersonal.name,
                    fin: arr[0].person.fin,
                    dateOfBirth: arr[0].person.dateOfBirth,
                    phone: arr[0].person.phone,
                    status: arr[0].status,
                }
            ]
            );

        } catch (error) {
            console.log(error);
        }
    };

      getData()
    
  }, [ props.id])

console.log(data);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td>Ad, soyad:</td>
                <td>{data[0]?.fullName}</td>
              </tr>
              <tr>
                <td>Fin:</td>
                <td>{data[0]?.fin}</td>
              </tr>
              <tr>
                <td>İxtisas:</td>
                <td>
                 {data[0]?.specialty}
                </td>
              </tr>
              <tr>
                <td>Doğum tarixi:</td>
                <td>
                  {moment(data[0]?.dateOfBirth).format('DD.MM.YYYY')}
                </td>
              </tr>
              <tr>
                <td>Cins</td>
                <td>{data[0]?.gender === 1 ? 'Qadın' : 'Kişi'}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col md={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td>Telefon:</td>
                <td>{data[0]?.phone}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td> {data[0]?.gmail}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>{
                    data[0]?.status === 1 ? <span style={{
                        fontSize: '11px',
                        backgroundColor: '#E49F33',
                    }}
                        className="statusBox">Gözləmədə</span> : data[0]?.status === 2 ? <span style={{
                            fontSize: '11px',
                            backgroundColor: "#7FBFA4",
                        }}
                            className="statusBox">Təsdiq</span> : data[0]?.status === 3 ? <span style={{
                                fontSize: '11px',
                                backgroundColor: "#D24C41",
                            }}
                                className="statusBox">imtina</span> : null
                    }
           
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

      </Row>
    </div>
  );
};

export default PersonDetails;