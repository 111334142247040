import React, { Component } from "react";
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import { whiteSpace, noWhitespace, number } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import {
  Spin,
  Col,
  Input,
  Modal,
  Form,
  Row,
  Button,
  Switch,
  Select,
  InputNumber,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { aictia } from "../../../const/api";
import JoditEditor from "jodit-react";

class EditPacket extends Component {
  formRef = React.createRef();
  editor = React.createRef();
  mainUrl = "PacketOfCooperative";
  id = this.props.match.params.id;
  constructor(props) {
    super(props);
  }

  state = {
    isMember: false,
    isBooklet: false,
    aboutt: {},
  };

  componentDidMount() {
    if (this.id) {
      this.formRef.current.resetFields();
      let obj = {};

      const getPost = async () => {
        await aictia.get(this.mainUrl + "/" + this.id).then((res) => {
          this.setState({
            isMember: res.data.documentConfirmingMembership,
            isBooklet: res.data.freeBooklets,
          });

          res.data.packetOfCooperative_LanguageList.forEach((d) => {
            obj[`title_${d.language}`] = d.name;
            obj[`body_${d.language}`] = d.description;
          });
          obj["Price"] = res.data.price;
          obj["MeetingsWithLeaders"] = res.data.meetingsWithLeaders;
          obj["PresentationAtTheBusinessForum"] =
            res.data.presentationAtTheBusinessForum;
          obj["PresentationAtCEOLunch"] = res.data.presentationAtCEOLunch;
          obj["RepresentationInTheManagement"] =
            res.data.representationInTheManagement;
          obj["RepresentInTheCommittee"] = res.data.representInTheCommittee;
          obj["RightToPlaceInfoCompanyOnWebsite"] =
            res.data.rightToPlaceInfoCompanyOnWebsite;
          obj["RightToPlaceStartupOnWebsite"] =
            res.data.rightToPlaceStartupOnWebsite;
          obj["RightToPlaceProjectOnWebsite"] =
            res.data.rightToPlaceProjectOnWebsite;
          obj["RightToPlaceInnovationsOnWebsite"] =
            res.data.rightToPlaceInnovationsOnWebsite;
          obj["RightToPlaceInventionsOnWebsite"] =
            res.data.rightToPlaceInventionsOnWebsite;
          obj["RightToPlaceInterviewHeadOnWebsite"] =
            res.data.rightToPlaceInterviewHeadOnWebsite;
          obj["CountOfPressRelease"] = res.data.countOfPressRelease;
          obj["RihtToVoteInDecisions"] = res.data.rihtToVoteInDecisions;
          obj["RihtToVoteOnAwards"] = res.data.rihtToVoteOnAwards;
          obj["CountOfRollUpInLobby"] = res.data.countOfRollUpInLobby;
          obj["CountOfRollUpInsideHall"] = res.data.countOfRollUpInsideHall;
          obj["CountOfRollUpInLobbyCeoLunch"] =
            res.data.countOfRollUpInLobbyCeoLunch;

          console.log(obj);
          if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(obj);
          }
        });
      };

      getPost();
    }
  }

  saveItem = async (values) => {
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    let obj = {
      ...values,
      PacketOfCooperative_LanguageList: langs.map((l) => {
        return {
          Language: l.key,
          Name: values[`title_${l.key}`],
          Description: values[`body_${l.key}`],
        };
      }),
      DocumentConfirmingMembership: this.state.isMember,
      FreeBooklets: this.state.isBooklet,
    };

    if (!this.id) {
      await aictia
        .post(this.mainUrl, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
          console.log(obj, "+++++");
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    } else {
      obj["id"] = this.props.match.params.id;
      await aictia
        .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    }
  };

  errorGenerator(error) {
    notify("Xəta baş verdi", false);
  }

  render() {
    let { t, getLangs, notify } = this.props;
    const { TextArea } = Input;
    console.log(this.state.categories);
    const lang = [
      {
        key: "az",
        id: 1,
      },
      {
        key: "en",
        id: 2,
      },
    ];
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
              <div className="page-name">
                <PicCenterOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">
                  Paketi {this.id ? "i redaktə et" : "  əlavə et"}{" "}
                </span>
              </div>
              <Link
                to={{
                  pathname: `/corporative-registration`,
                  state: {
                    locales: null,
                    name: this.props.location.state.name,
                  },
                }}
              >
                <Button type={"primary"}>{t("cancel")}</Button>
              </Link>
            </div>
          </Col>
          <Col xs={24}>
            <div className={`p-2 animated edit fadeInUp bg-white`}>
              <Form
                ref={this.formRef}
                onFinish={this.saveItem}
                layout="vertical"
              >
                <Row gutter={[55, 35]}>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>Ad</p>
                    {lang.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`title_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>
                  <Col xs={24}>
                    {/*<div className="mb-10">Kontent</div>*/}
                    {langs.map((l) => (
                      <div key={l.id} className="form-lang">
                        <div className="mb-10">{l.name}</div>
                        <Form.Item
                          name={`body_${l.key}`}
                          validateTrigger="onChange"
                          // getValueFromEvent={(event, editor) => {
                          //     const dat = editor.getData();
                          //     return dat;
                          // }}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <JoditEditor
                            ref={this.editor}
                            value={this.state.aboutt[`about_${l.key}`]}
                            tabIndex={1} // tabIndex of textarea
                          />
                        </Form.Item>
                      </div>
                    ))}
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isMember}
                        onChange={() => {
                          this.setState({ isMember: !this.state.isMember });
                        }}
                      />
                      <span className="mr-15">
                        {" "}
                        AİKTSA Üzvlüyü Təsdiqləyən sənəd:
                      </span>
                      <span className={this.state.isMember ? "green" : "red"}>
                        {this.state.isMember ? "+" : "-"}
                      </span>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isBooklet}
                        onChange={() => {
                          this.setState({ isBooklet: !this.state.isBooklet });
                        }}
                      />
                      <span className="mr-15">
                        {" "}
                        AİKTSA-nın tədbirlərində şirkətin ödənişsiz bukletlərini
                        yaymaq
                      </span>
                      <span className={this.state.isBooklet ? "green" : "red"}>
                        {this.state.isBooklet ? "+" : "-"}
                      </span>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      Biznes-forum və dəyirmi masa çərçivəsində AİKTSA
                      üzvlərinin dövlət qurumlarının rəhbər və nümayəndəlikləri
                      ilə görüşlərində iştirak
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"MeetingsWithLeaders"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>Biznes-forumda təqdimat (5dəqiqə)</p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"PresentationAtTheBusinessForum"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>CEO Lunch-da təqdimat (5dəqiqə)</p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"PresentationAtCEOLunch"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKSTA-nın idarəetməsində təmsil olunma və (rəhbərlik
                      etmə) seçilmək hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RepresentationInTheManagement"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın Təşkilat Komitəsində iştirak hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RepresentInTheCommittee"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      www.aictia.org saytında şirkət barədə xəbər yerləşdirmək
                      hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceInfoCompanyOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      www.aictia.org saytında şirkətin startupları yerləşdirmək
                      hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceStartupOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      www.aictia.org saytında şirkətin layihələri yerləşdirmək
                      hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceProjectOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      www.aictia.org saytında şirkətin innovasiyaları
                      yerləşdirmək hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceInnovationsOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      www.aictia.org saytında şirkətin ixtiraları yerləşdirmək
                      hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceInventionsOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      Şirkət rəhbərinin müsahibəsini www.aictia.org portalında
                      yerləşdirmək hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RightToPlaceInterviewHeadOnWebsite"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      Şirkətin fəaliyyəti ilə bağlı press-relizlərin, xüsusi
                      aksiya və təkliflərin Klubun elektron ünvan bazası ilə
                      göndərilən həftəlik bülletenlərə daxil edilməsi
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"CountOfPressRelease"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA müzakirələrində qəbul edilən qərarlarda səsvermə
                      hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RihtToVoteInDecisions"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      Mükafatlandırılmalarda səsvermə hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"RihtToVoteOnAwards"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın biznes-forumlar keçirdiyi zalın foyesində roll
                      up yerləşdirmə
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"CountOfRollUpInLobby"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın biznes-forumlar keçirdiyi zalın daxilində roll
                      up yerləşdirmə
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"CountOfRollUpInsideHall"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      CEO lunch keçirildiyi zalın foyesində roll up yerləşdirmək
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"CountOfRollUpInLobbyCeoLunch"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın aylıq korperativ üzvlük qiymətləri
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"Price"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col xs={24}>
                    <div className={"flex"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/corporative-registration`,
                          state: {
                            locales: null,
                            name: this.props.location.state.name,
                          },
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>

        <Modal
          visible={this.state.previewVisible}
          title={false}
          footer={null}
          onCancel={() => {
            this.setState({ previewVisible: false });
          }}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

const exp = withTranslation()(EditPacket);
export default connect(mapStateToProps, { notify })(exp);
