

import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Upload,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
    ToolFilled
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace, noWhitespace } from "../../../../utils/rules";
const Users = (props) => {
    const mainUrl = "User"
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([])
    const [text, setText] = useState({
        name_az: '',
        name_en: ''
    })
    const [previewVisible, setPreviewVisible] = useState(false)
    const [previewImage, setPreviewImage] = useState([])
    const [file, setFile] = useState()
    const [isActive, setActive] = useState(false);
    const [image, setImage] = useState({})
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const [reset, setReset] = useState(null)
    const [message, setMessage] = useState(null)
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "userName", value: t("name"), con: true },
        { key: "image", value: "Şəkil", con: true },
        { key: "id", value: "", con: true },
    ];
    const nameInput = useRef();
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: "Şəkil",
            dataIndex: "image",
            key: "9",
            render: (i) => {
                console.log(i);
                return <img className={"tableImage"} src={i.url} alt="" />;
            },
        },
        {
            title: "Ad",
            dataIndex: "userName",
            key: "10"
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                console.log(i);
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                        <Tooltip className="ml-5" title={'Şifrəni yenilə'} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setReset(i)}
                            >
                                <ToolFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        await aictia.get(`${mainUrl}/${i}`).then((res) => {

            setFile(res.data.image);
            setImage(res.data.image)
            setFileList([{
                uid: res.data.image,
                url: res.data.image.url,
            }]);

            let obj = {};
            console.log(res.data);
            obj['FullName'] = res.data.fullName;
            obj['UserName'] = res.data.userName;
            obj['Position'] = res.data.position;
            // setActive(names.is_active === 1)
            console.log(obj);
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        setFile(null)
        setFileList([])
        setActive(false)
        setImage(null)
        setText({})
        setReset(false)
        form.resetFields();
    };


    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList)
        if (newFileList.length <= 0) {
            setFile(null)
            setImage(null)
        }
    };


    const getBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }

    const handlePreview = async (file) => {

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview)
        setPreviewVisible(true)
    };

    const setUploadFile = ({ onSuccess, onError, file }) => {
        let form_data = new FormData();
        const filename = Math.random(1, 999999) + Date.now() + file.name;
        form_data.append("_file", file, filename);
        aictia
            .post("UploadFile", form_data, {
                headers: {
                    "content-type": "multipart/form-data",
                },
            })
            .then((res) => {
                setFile(res.data.url)
                setImage(res.data)
                onSuccess(null, file);
            })
            .catch((err) => onError());
    };


    const deletePosition = async (i) => {
        await aictia
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const resetPassword = async (values) => {
        let obj = {
            Id: reset,
            Password: values.Password,
            RepetitivePassword: values.RepetitivePassword
        };
        if(values.Password !== values.RepetitivePassword){
            setMessage("Şifrələr eyni deyil");
            return;
        }else{  
            setMessage(null)
        }
        if(values.Password === values.RepetitivePassword){
        await aictia
            .put(`${mainUrl}/ResetPassword/${reset}`,obj)
            .then(() => {
                // description
                notify("Şifrə yeniləndi", true);
                cancelEditing();
                console.log(obj);
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
        }

    };


    const savePosition = async (values) => {
       
        if (values.Password !== values.RepetitivePassword) {
            
            setMessage("Şifrələr eyni deyil");
            return;
        }else{
            setMessage(null)
        }
        let obj = {
            Image: image,
            FullName: values.FullName,
            UserName: values.UserName,
            Position: values.Position,
            Password: values.Password,
            RepetitivePassword: values.RepetitivePassword
        };
        if (!editing && values.Password === values.RepetitivePassword) {
            await aictia
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } if (editing && values.Password === values.RepetitivePassword) {
            obj["id"] = editing;
            await aictia
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await aictia.get(mainUrl, { params: { per_page: 20000 } }).then((res) => {
            setSpin(false);
            console.log(res.data);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                        // name: p.locales.find((l) => {
                        //     return l.local === localStorage.getItem("locale");
                        // }).name,
                    };
                })
            );
        });
    };
    console.log(message)

    useEffect(() => {
        getPositions();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">İstifadəçilər</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    {
                        reset ?
                            <Form layout="vertical" onFinish={resetPassword} form={form}>
                                <div className="form-lang mb-20">
                                    <div className="mb-10">
                                        Şifrə
                                    </div>
                                    <Form.Item
                                        className="mb-5"
                                        validateTrigger="onChange"
                                        name="Password"
                                        rules={[
                                            whiteSpace(t("addPasswordError")),
                                            {
                                                min: 6,
                                                message: t("minimumPasswordError"),
                                            },
                                        ]}
                                    >
                                        
                                        <Input.Password autoComplete="off" />
                                     
                                    </Form.Item>   
                                    {message && <div className="text-danger">{message}</div>}
                                </div>
                                <div className="form-lang mb-10">
                                    <div className="mb-10">
                                        Şifrənin təkrarı
                                    </div>
                                    <Form.Item
                                        className="mt-5"
                                        validateTrigger="onChange"
                                        name="RepetitivePassword"
                                        rules={[
                                            whiteSpace(t("addPasswordError")),
                                            {
                                                min: 6,
                                                message: t("minimumPasswordError"),
                                            },
                                        ]}
                                    >
                                        <Input.Password autoComplete="off" />
                                    </Form.Item>
                                    {message && <div className="text-danger">{message}</div>}
                                </div>
                                <div className="flex flex-between mt-15">
                                    <Button onClick={cancelEditing}>{t("cancel")}</Button>
                                    <Button htmlType="submit">Yenilə</Button>
                                </div>
                            </Form>
                            :
                            <Form layout="vertical" onFinish={savePosition} form={form}>
                                <Form.Item
                                    className={'animated fadeIn'}
                                    validateTrigger="onChange"
                                    name={`photo`}
                                    required
                                >

                                    <Upload
                                        accept=".png, .jpg , .jpeg, .svg"
                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                        listType="picture-card"
                                        fileList={fileList}
                                        onChange={onChange}
                                        onPreview={handlePreview}
                                        customRequest={setUploadFile}
                                        beforeUpload={null}
                                    >
                                        {fileList.length < 1 && "+ Yüklə"}
                                    </Upload>
                                    {/* {/</ImgCrop>/} */}
                                </Form.Item>
                                <div className="form-lang">
                                    <div className="mb-10">
                                        Ad, soyad, ata adı
                                    </div>
                                    <Form.Item
                                        name={'FullName'}
                                        validateTrigger="onChange"
                                        // getValueFromEvent={(event, editor) => {
                                        //     const dat = editor.getData();
                                        //     return dat;
                                        // }}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Input ref={nameInput} />

                                    </Form.Item>
                                </div>
                                <div className="form-lang">
                                    <div className="mb-10">
                                        İstifadəçi adı
                                    </div>
                                    <Form.Item
                                        name={'UserName'}
                                        validateTrigger="onChange"
                                        // getValueFromEvent={(event, editor) => {
                                        //     const dat = editor.getData();
                                        //     return dat;
                                        // }}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Input ref={nameInput} />
                                    </Form.Item>
                                </div>
                                {
                                    !editing
                                    &&
                                    <>
                                        <div className="form-lang mb-20">
                                            <div className="mb-10">
                                                Şifrə
                                            </div>
                                            <Form.Item
                                                className="mb-5"
                                                validateTrigger="onChange"
                                                name="Password"
                                                rules={[
                                                    whiteSpace(t("addPasswordError")),
                                                    {
                                                        min: 6,
                                                        message: t("minimumPasswordError"),
                                                    },
                                                ]}
                                            >
                                                <Input.Password autoComplete="off" />
                                            </Form.Item>
                                            {message && <div className="text-danger">{message}</div>}
                                        </div>
                                        <div className="form-lang mb-10">
                                            <div className="mb-10">
                                                Şifrənin təkrarı
                                            </div>
                                            <Form.Item

                                                validateTrigger="onChange"
                                                name="RepetitivePassword"
                                                rules={[
                                                    whiteSpace(t("addPasswordError")),
                                                    {
                                                        min: 6,
                                                        message: t("minimumPasswordError"),
                                                    },
                                                ]}
                                            >
                                                <Input.Password autoComplete="off" />
                                            </Form.Item>
                                            {message && <div className="text-danger">{message}</div>}
                                        </div>
                                    </>
                                }
                                <div className="form-lang">
                                    <div className="mb-10">
                                        Vəzifəsi
                                    </div>
                                    <Form.Item
                                        name={'Position'}
                                        validateTrigger="onChange"
                                        // getValueFromEvent={(event, editor) => {
                                        //     const dat = editor.getData();
                                        //     return dat;
                                        // }}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Input ref={nameInput} />
                                    </Form.Item>
                                </div>

                                <div className="flex  flex-between mt-15">
                                    <Button onClick={cancelEditing}>{t("cancel")}</Button>
                                    <Button htmlType="submit">{t("save")}</Button>
                                </div>
                            </Form>
                    }
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Users);