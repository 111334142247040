import "@ant-design/compatible/assets/index.css";

import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Tabs,
  Button,
  Table,
  Select,
  Form,
  Tooltip,
  Modal,
  Col,
  Popconfirm,
  
} from "antd";
import { Dropdown } from "antd";

import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { aictia } from "../../../const/api";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DndContext } from "@dnd-kit/core";

import {
  PicCenterOutlined,
  DeleteFilled,
  EditFilled,
  ArrowRightOutlined,
  UnorderedListOutlined,
  ExclamationCircleOutlined,
  MenuOutlined,
  ArrowsAltOutlined 
} from "@ant-design/icons";

import moment from "moment";
import { convertColumns } from "../../../utils/columnconverter";

const { TabPane } = Tabs;
const { Option } = Select;

const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      
    >
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <ArrowsAltOutlined 
              {...listeners}
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                  fontSize:"20px",
                }}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const CentralTableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      
    >
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <ArrowsAltOutlined 
              {...listeners}
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                  fontSize:"20px",
                }}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const Managment = (props) => {
  const { t } = useTranslation();
  const { notify } = props;

  let managementUrl = "Staff/BoardOfDirectors";
  let centralUrl = "Staff/CentralStaff";
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "image", value: ("Photo"), con: true },
    { key: "name", value: t("Ad Soyad "), con: true },
    { key: "post", value: t("Vəzifə"), con: true },
    { key: "id", value: "", con: false },
  ];
  const docCols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "image", value: ("Photo"), con: true },
    { key: "name", value: t("Ad Soyad "), con: true },
    { key: "post", value: t("Vəzifə"), con: true },
    { key: "id", value: "", con: false },
  ];
  let [trigger, setTrigger] = useState(1);

  const [doc, setDoc] = useState([]);
  const [spin, setSpin] = useState(false);
  const [postlist, setPostList] = useState([]);

  const [form] = Form.useForm();
  const getTaskManagement = () => {
    aictia.get(managementUrl).then((res) => {
      res.data && setSpin(false);
      setDoc(
        res.data.map((d, index) => {
          return {
            ...d,
            name: d.person.name,
            key: index + 1,
            index,
            tableIndex: index + 1,
          };
        })
      );
    });
  };
  const getTaskCentral = () => {
    aictia.get(centralUrl).then((res) => {
      console.log(res.data);
      res.data && setSpin(false);
      setPostList(
        res.data.map((d, index) => {
          return {
            ...d,
            name: d.person.name,
            key: index + 1,
            index,
            tableIndex: index + 1,
          };
        })
        
      );
    });
  };
  console.log(postlist);
  useEffect(() => {
   

    getTaskManagement();
    getTaskCentral();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteManagement = async (i) => {
    console.log("salam");
    if (i === 0 || i) {
      await aictia
        .delete(`${managementUrl}/${i}`)
        .then((res) => {
          getTaskManagement();
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const deleteCentral = async (i) => {
    console.log("salam");
    if (i === 0 || i) {
      await aictia
        .delete(`${centralUrl}/${i}`)
        .then((res) => {
          getTaskCentral();
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };
  const dragManagement = async () => {
    console.log("salam");
    await aictia
        .put(`${managementUrl}`, doc)
        .then((res) => {
        getTaskManagement()
        notify("", true);
          
        })
        .catch((res) => {
          notify(res.err, false);
          
        });
  };


 const dragCentral = async () => {
    console.log(postlist);
    await aictia
        .put(`${centralUrl}`, postlist)
        .then((res) => {
        getTaskCentral()
        notify("", true);
          
        })
        .catch((res) => {
          notify(res.err, false);
          
        });
  };




  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDoc((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };
  const onDragEndCentral = ({ active, over }) => {
    if (active.id !== over?.id) {
      setPostList((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };


  const columns = [
    {
      key: "sort",
    },
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: "Photo",
      dataIndex: "image",
      key: "9",
      render: (i) => {
        return <img className={"tableImage"} src={i.url} alt="" />;
      },
    },
    {
      title: "Ad Soyad",
      dataIndex: "name",
      key: "2",
    },
    {
      title: "Vəzifə",
      dataIndex: "post",
      key: "2",
    },
    {
      title: "",
      dataIndex: "id",
      key: "",
      render: (i) => {
        console.log(i);
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/managment/edit/${i}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
          
            <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deleteManagement(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
           
          </div>
        );
      },
    },
  ];

  //   {
  //     title: "Ehtiyyat hissəsi",
  //     key: 1,
  //     dataIndex: "techniqueSpareParts",
  //   },
  //   {
  //     title: t("quantityOf"),
  //     key: 3,
  //     dataIndex: "count",
  //   },
  //   {
  //     title: "Vin",
  //     key: 3,
  //     dataIndex: "vin",
  //   },
  // ];
  const columnsIncomeExpense = [
    {
      key: "sort",
    },
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: "Photo",
      dataIndex: "image",
      key: "9",
      render: (i) => {
        return <img className={"tableImage"} src={i.url} alt="" />;
      },
    },
    {
      title: "Ad Soyad",
      dataIndex: "name",
      key: "2",
    },
    {
      title: "Vəzifə",
      dataIndex: "post",
      key: "2",
    },
    {
      title: "",
      dataIndex: "id",
      key: "",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/managment/edit/${i}`,
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deleteCentral (i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  // const triggerFetch = () => {
  //   setTrigger(++trigger);
  //   setReservedFetched(false);
  //   setVisibleAPurchase(false);
  // };

  //   {
  //     title: "Ehtiyyat hissəsi",
  //     key: 1,
  //     dataIndex: "techniqueSpareParts",
  //   },
  //   {
  //     title: t("quantityOf"),
  //     key: 3,
  //     dataIndex: "count",
  //   },
  //   {
  //     title: "Vin",
  //     key: 3,
  //     dataIndex: "vin",
  //   },
  //   {
  //     title: "Statusu",
  //     key: 3,
  //     dataIndex: "haveOrdered",
  //     render(i) {
  //       return (
  //         <div>
  //           {i ? "Satınalma sənədi var" : "Satınalma sənədi yaradilmayib"}
  //         </div>
  //       );
  //     },
  //   },
  //   {
  //     key: 3,
  //     dataIndex: "docs",
  //     render(i) {
  //       return (
  //         <div>
  //           {!i.haveOrdered ? (
  //             <Button
  //               onClick={() => {
  //                 openPurchaseModal(i);
  //               }}
  //             >
  //               Satınalma sənədi yarat
  //             </Button>
  //           ) : (
  //             ""
  //           )}
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <div>
      <div className="border page-heading flex p-2 mt-0 bg-white">
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UnorderedListOutlined className="f-20 mr5-15" />

              <span className="f-20 bold">İdarəetmə</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/managment/create`,
                  state: { locales: null },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>
      </div>
      <div className="position-relative mt-15">
        <div className="position-absolute w-100 tab-section">
          <Tabs animated={true} defaultActiveKey="1" className="w-100">
            <TabPane tab={"İdarəetmə heyəti"} key="1">
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={doc.map((i) => i.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    size="small"
                    className="bg-white   w-100"
                    columns={columns}
                    dataSource={convertColumns(doc,  docCols)}
                    components={{
                      body: {
                        row: TableRow,
                      },
                    }}
                    pagination={
                      {
                        // pageSizeOptions: ["10", "15", "20", "25", "30"],
                        // showSizeChanger: true,
                        // current_page: 1,
                      }
                    }
                  />
                </SortableContext>
              </DndContext>

              <div className="sortbutton">
                <Button className={"mr-15 "} type={"primary"} onClick={()=>dragManagement()}>
                  {t("save")}
                </Button>
              </div>
            </TabPane>

            <TabPane tab={"Mərkəzi İdarəetmə heyəti"} key="2">
            <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEndCentral }
              >
                <SortableContext
                  items={postlist.map((i) => i.key)}
                  strategy={verticalListSortingStrategy}
                >
              <Table
                size="small"
                className="bg-white   w-100"
                columns={columnsIncomeExpense}
                dataSource={convertColumns(postlist, cols)}
                components={{
                  body: {
                    row: CentralTableRow,
                  },
                }}
                pagination={
                  {
                    // pageSizeOptions: ["10", "15", "20", "25", "30"],
                    // showSizeChanger: true,
                    // current_page: 1,
                  }
                }
              />
              </SortableContext>
              </DndContext>
              <div className="sortbutton">
                <Button className={"mr-15 "} type={"primary"} onClick={()=>dragCentral()}>
                  {t("save")}
                </Button>
              </div>

            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { notify })(Managment);
