import React, {useEffect, useState} from "react";
import {Button, Card, Col, Form, Input, Row,} from "antd";
import {noWhitespace} from "../../../utils/rules";
import {PicCenterOutlined,PlusOutlined,MinusOutlined} from "@ant-design/icons";
import { notify } from "../../../redux/actions";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import aictia from "../../../const/api";
import history from "../../../const/history";
import { useParams } from "react-router-dom";

const EditContact = (props) => {
    let mainUrl = 'Contact'
    const {notify} = props;
    const {id} = useParams();
    const [form] = Form.useForm();
    const [mailNum,setMailNum] = useState(1);
    const [phoneNum,setPhoneNum] = useState(1);
    const {t} = useTranslation();
    const langValue = [
    {
        id:1,
        key:'az'
    },
    {
        id:2,
        key:'en'
    }
    ]
    const saveItem = (values) => {
        console.log(values);
        const newData = {
            ...values,
            contact_LanguageList: langValue.map((l) => {
                return {
                    language: l.key,
                    adress: values[`adres_${l.key}`],
                };
            }),
        }
        if (id){
            newData["id"] = id;
            aictia
                .put(mainUrl + '/' + id,newData)
                .then(() => {
                    notify("", true);
                    window.history.back();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
       else{
            aictia
                .post(mainUrl,newData)
                .then(() => {
                    notify("", true);
                       window.history.back();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getInfos = () => {
        aictia.get(mainUrl + '/' + id).then((res) => {
            console.log(res.data);
            
            res.data.contact_LanguageList.forEach((d) => {
                res.data[`adres_${d.language}`] = d.adress;
            });
            console.log(res.data);
            if (res.data) {
                setMailNum(res.data.mailList.length)
                setPhoneNum(res.data.phoneList.length)
                form.setFieldsValue({
                    ...res.data
                });
            }
        });
    };



    useEffect(() => {
        getInfos();
    }, [t]);

    return (
        <div>
            <Form form={form} onFinish={saveItem} layout="vertical">
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                            <div className="page-name">
                                <PicCenterOutlined className="f-20 mr5-15"/>
                                <span className="f-20 bold">Əlaqə məlumatları</span>
                            </div>
                            <div>
                                <Button htmlType={"submit"}>{t("save")}</Button>
                                <Link to={"/contact"}>
                                    {" "}
                                    <Button type={"primary"}>{t("cancel")}</Button>
                                </Link>
                            </div>
                        </div>
                    </Col>

                    <Col xs={24}>
                        <Card
                            className={"d-block h-100 animated fadeInRight"}
                        >
                            <div className={"border p-2 pb-1"}>
                                <Row gutter={[8, 0]}>
                                    <Col xs={24}>
                                            <div className="form-lang">
                                            {langValue.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <Form.Item
                                                    validateTrigger="onChange"
                                                    className="mb-5"
                                                    name={`adres_${l.key}`}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                                    <Input placeholder={'Address'} />
                                                </Form.Item>
                                                <span className="input-lang">{l.key}</span>
                                            </div>
                                        ))}
                                            </div>
                                    </Col>


                                    <Col xs={12}>
                                        <div className="p-2 border">
                                            <div className="flex mb-15  flex-align-center flex-between">
                                                <span>Telefon</span>
                                            </div>
                                            {
                                                [...Array(phoneNum)].map((_,index) => (
                                                    <div  className="mb-10 " key={index}>
                                                    <Form.Item
                                                        className={"mb-0 w-100 mr-10"}
                                                        validateTrigger="onChange"
                                                        name={["phoneList",index, "phone"]}
                                                        rules={[
                                                            noWhitespace(t("inputError")),
                                                            {
                                                                min: 10,
                                                                message:
                                                                    "Əlaqə nömrəsi tam daxil edilməlidir !",
                                                            },
                                                        ]}
                                                        
                                                    >
                                                         <Input placeholder={"+9940125341232"}/>
                                                    </Form.Item>
                                                </div>
                                                ))
                                            }
                                           
                                            <div>
                                                <Button
                                                    onClick={() => setPhoneNum(phoneNum + 1)}
                                                    className={"mr-10"}
                                                    type={"primary"}
                                                    shape={"circle"}
                                                    icon={<PlusOutlined/>}
                                                />
                                                <Button
                                                    onClick={() => setPhoneNum(phoneNum - 1)}
                                                    type={"primary"}
                                                    shape={"circle"}
                                                    icon={<MinusOutlined/>}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12}>
                                        <div className="p-2 border">
                                           <span>
                                            <div className="flex mb-15  flex-align-center">
                                              <span>Email</span>
                                            </div>
                                            {
                                                [...Array(mailNum)].map((_,index) => (
                                            <Form.Item
                                                className={"mb-10"}
                                                validateTrigger="onSave"
                                                name={["mailList",index,"mail"]}
                                                key={index}
                                                rules={[
                                                    {
                                                        type: "email",
                                                        message: "Emaili düzgün daxil edin !",
                                                    },
                                                    noWhitespace(t("inputError")),
                                                ]}
                                            >
                                              <Input placeholder={"info@anama.az"}/>
                                            </Form.Item>
                                               ))
                                            }
                                            <div>
                                                <Button
                                                    onClick={() => setMailNum(mailNum + 1)}
                                                    className={"mr-10"}
                                                    type={"primary"}
                                                    shape={"circle"}
                                                    icon={<PlusOutlined/>}
                                                />
                                                <Button
                                                    onClick={() => setMailNum(mailNum - 1)}
                                                    type={"primary"}
                                                    shape={"circle"}
                                                    icon={<MinusOutlined/>}
                                                />
                                            </div>
                                          </span>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};


const mapStateToProps = ({langs}) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, {notify})(EditContact);