import React from "react";
import { aictia } from "../../../const/api";
import { useParams, Link } from "react-router-dom";
import {
  Col,
  Row,
  Button,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";

function Detail() {
  const id = useParams().id;
  const mainUrl = "PacketOfCooperative";
  const [data, setData] = React.useState();

  const getData = async () => {
    try {
      const res = await aictia.get(mainUrl);
      const arr = res.data.filter((elem) => elem.id === Number(id));
      setData(arr);
      
    } catch (error) {
      console.log(error);
    }
  };
  
  React.useEffect(() => {
    getData();
  }, []);

  const headers = [
    {
      az: "AİKTSA üzvlüyü təsdiqləyən sertifikat",
      eng: "documentConfirmingMembership",
    },
    {
      az: "Biznes-forum və dəyirmi masa çərçivəsində AİKTSA üzvlərinin dövlət qurumlarınınrəhbər və nümayəndəlikləri ilə görüşlərində iştirak",
      eng: "meetingsWithLeaders",
    },
    {
      az: "Biznes-forumda təqdimat (5dəqiqə)",
      eng: "presentationAtTheBusinessForum",
    },
    {
      az: "CEO Lunch-da təqdimat (5dəqiqə)",
      eng: "presentationAtCEOLunch",
    },
    {
      az: "AİKSTA-nın idarəetməsində təmsil olunma və rəhbərlik etmə hüququ",
      eng: "representationInTheManagement",
    },
    {
      az: "AİKTSA-nın Təşkilat Komitəsində iştirak hüququ",
      eng: "representInTheCommittee",
    },
    {
      az: "www.aictia.org saytında şirkət barədə xəbər yerləşdirmək hüququ",
      eng: "rightToPlaceInfoCompanyOnWebsite",
    },
    {
      az: "www.aictia.org saytında şirkətin startupları yerləşdirmək hüququ",
      eng: "rightToPlaceStartupOnWebsite",
    },
    {
      az: "www.aictia.org saytında şirkətin innovasiyaları yerləşdirmək hüququ",
      eng: "rightToPlaceInnovationsOnWebsite",
    },
    {
      az: "www.aictia.org saytında şirkətin  ixtiraları yerləşdirmək hüququ",
      eng: "rightToPlaceInventionsOnWebsite",
    },
    {
      az: "Şirkət rəhbərinin müsahibəsini www.aictia.org portalında yerləşdirmək hüququ",
      eng: "rightToPlaceInterviewHeadOnWebsite",
    },
    {
      az: "Şirkətin fəaliyyəti ilə bağlı press-relizlərin, xüsusi aksiya və təkliflərin Klubun elektron ünvan bazası ilə göndərilən həftəlik bülletenlərə daxil edilməsi",
      eng: "countOfPressRelease",
    },
    {
      az: "AİKTSA müzakirələrində qəbul edilən qərarlarda səsvermə hüququ",
      eng: "rihtToVoteInDecisions",
    },
    {
      az: "Mükafatlandırılmalarda səsvermə hüququ",
      eng: "rihtToVoteOnAwards",
    },
    {
      az: "AİKTSA-nın biznes-forumlar keçirdiyi zalın foyesində roll up yerləşdirmə",
      eng: "countOfRollUpInLobby",
    },
    {
      az: "AİKTSA-nın biznes-forumlar keçirdiyi zalın daxilində roll up yerləşdirmə",
      eng: "countOfRollUpInsideHall",
    },
    {
      az: "CEO lunch keçirildiyi zalın foyesində roll up yerləşdirmək",
      eng: "countOfRollUpInLobbyCeoLunch",
    },
    {
      az: "AİKTSA-nın tədbirlərində şirkətin ödənişsiz bukletlərini yaymaq",
      eng: "freeBooklets",
    },
    {
      az: "AİKTSA-nın aylıq korperativ üzvlük qiymətləri",
      eng: "price",
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Ətraflı</span>
            </div>
            <Link
              to={{
                pathname: `/corporative-registration`,
              }}
            >
              <Button type={"primary"}>Çıxış</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <div className="membershipTable">
        <table className="table">
          <thead>
            <tr>
              <th>Üstünlüklər</th>
              {data?.map((item, index) => (
                <th key={index}>{item?.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {headers.map((item, index) => (
              <tr key={index}>
                <td className="statik">
                  <p>{item.az}</p>
                </td>
                {data?.map((backItem) => {
                  let tableRow = Object.keys(backItem);
                  let tableData = Object.values(backItem);
                  return tableRow.map((tableItem, tableIndex) => {
                    if (tableItem === item.eng) {
                      if (tableItem === "meetingsWithLeaders") {
                        return (
                          <td>{`${tableData[tableIndex]} iştirakçı`}</td>
                        );
                        
                      }
                      if (tableItem === "presentationAtTheBusinessForum" || tableItem === "presentationAtCEOLunch" || tableItem === "rightToPlaceInterviewHeadOnWebsite") {
                        return (
                          <td>{`ildə ${tableData[tableIndex]} dəfə`}</td>
                        );
                        
                      }
                      if (tableItem === "representationInTheManagement" || tableItem ==="representInTheCommittee") {
                        return (
                          <td>{`${tableData[tableIndex]} üzv`}</td>
                        );
                      }
                      if (tableItem === "rightToPlaceInfoCompanyOnWebsite" ||tableItem ==="rightToPlaceStartupOnWebsite" ||tableItem==="rightToPlaceProjectOnWebsite"
                      || tableItem === "rightToPlaceInnovationsOnWebsite" || tableItem ==="rightToPlaceInventionsOnWebsite" || tableItem === "countOfPressRelease" ) {
                        return (
                          <td>{` ayda ${tableData[tableIndex]} dəfə`}</td>
                        );
                      }
    
                      if (tableItem === "rihtToVoteInDecisions" || tableItem === "rihtToVoteOnAwards") {
                        return (
                          <td>{`${tableData[tableIndex]} səs `}</td>
                        );
                      }
                    
                      if (tableItem === "countOfRollUpInLobby" || tableItem === "countOfRollUpInsideHall" || tableItem === "countOfRollUpInLobbyCeoLunch") {
                        return (
                          <td>{`${tableData[tableIndex]} roll up `}</td>
                        );
                      }

                      if (tableItem === "price") {
                        return (
                          <td>
                            <span className="price">
                              {`${tableData[tableIndex]} AZN/ay`}
                            </span>
                          </td>
                        );
                      }
                      return (
                        <td>
                          {tableData[tableIndex] === false
                            ? "-"
                            : tableData[tableIndex] === true
                            ? "+"
                            : tableData[tableIndex]}
                        </td>
                      );
                    }
                  });
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Col xs={24}>
        <div className={"flex"}>
          <Link
            to={{
              pathname: `/corporative-registration`,
            }}
          >
            <Button type={"primary"}>Ləğv et</Button>
          </Link>
        </div>
      </Col>

    </>
  );
}

export default Detail;
