import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
  Row,
  Col,
  Table,
  Button,
  Tooltip,
  Spin,
  Popconfirm,
  Form,
  Select
} from "antd";
import {
  PicCenterOutlined,
  DeleteFilled,
  EditFilled,
  ClearOutlined,
} from "@ant-design/icons";
import { aictia } from "../../../const/api";
import { notify } from "../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const { Option } = Select;

function StrategicPartnership(props) {
  let mainUrl = "StrategicPartnership";
  const [form] = Form.useForm();
  const [postlist, setPostList] = useState([]);
  const [spin, setSpin] = useState(false);
  const { t } = useTranslation();
  let [trigger, setTrigger] = useState(0);
  const [empty, setEmpty] = useState(false);
  const [filterData, setFilterData] = useState([]);
  
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "title", value: t("name"), con: true },
    { key: "photo", value: "Logo", con: false },
    { key: "categoryId", value: "Kateqoriya adı", con: false },
    { key: "id", value: "", con: false },
  ];

 

const onSearch=async (values)=>{
  const queryParams = values?.CategoryId.map((categoryId) => `CategoryId=${categoryId}`)
  .join("&");
  console.log(queryParams)
  setSpin(true);
  await aictia
  .get(`${mainUrl}?${queryParams}`)
  .then((res) => {
    setSpin(false)

    if(res.data.length === 0){
      setEmpty(true)
       }else{
      setEmpty(false)
  }
  setFilterData([...res.data]);
  })
  .catch((err) => {
    console.log(err)
  });
}

const clearFilter = () =>{
  form.resetFields()
  setFilterData([])
  setEmpty(false)  
}
  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: "Logo",
      dataIndex: "photo",
      key: "9",
      render: (i) => {
        return <img className={"tableImage"} src={i.url} alt="" />;
      },
    },
    {
      title: t("name"),
      dataIndex: "title",
      key: "2",
    },
    {
      title: "Kateqoriya",
      dataIndex: "categoryId",
      key: "4",
      render: (i) => {
        if (i === 1) {
          return <span>Dövlətlə tərəfdaşlıq</span>;
        } else if (i === 2) {
          return <span>Təşkilatlarla tərəfdaşlıq</span>;
        } else if (i === 3) {
          return <span>Beynalxalq tərafdaşlıq</span>;
        } else if (i === 4) {
          return <span>Digər tərafdaşlıqlar</span>;
        }
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
              <Link
                to={{
                  pathname: `/strategic-partnership/edit/${i}`,
                  state: {
                    locales: postlist.find((p) => {
                      return p.id == i;
                    })?.locales,
                  },
                }}
              >
                <Button className="border-none" type="text" shape="circle">
                  <EditFilled />
                </Button>
              </Link>
            </Tooltip>
            <Popconfirm
              placement="topRight"
              title={t("areYouSure")}
              onConfirm={() => deletePost(i)}
              okText={t("yes")}
              cancelText={t("no")}
            >
              <Tooltip className="ml-5" title={t("delete")}>
                <Button className="border-none" type="text" shape="circle">
                  <DeleteFilled />
                </Button>
              </Tooltip>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const { notify } = props;

  const deletePost = async (i) => {
    if (i === 0 || i) {
      await aictia
        .delete(`${mainUrl}/${i}`)
        .then((res) => {
          setTrigger(++trigger);
          notify("silindi", true);
        })
        .catch((res) => {
          notify(res.err, false);
        });
    }
  };

  const getPostList = () => {
    setSpin(true);
    aictia.get(mainUrl).then((res) => {
      res.data && setSpin(false);
      setPostList(
        res.data.map((d, index) => {
          return {
            ...d,
            key: index + 1,
            index,
            tableIndex: index + 1,
          };
        })
      );
    });
  };

  useEffect(() => {
    getPostList();
  }, [t, trigger]);
  return (
    <div>
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Strateji tərəfdaşlıq</span>
            </div>
            <div>
              <Link
                to={{
                  pathname: `/strategic-partnership/create`,
                  state: { locales: null },
                }}
              >
                <Button type={"primary"}>Əlavə et</Button>
              </Link>
            </div>
          </div>
        </Col>

        <Col xs={24}>
          <Form layout="vertical" onFinish={onSearch} form={form}>
            <div className="commontask bg-white px-2 pt-15">
              <Row className={"mt-5"} gutter={[16, 8]}>
               <Col md={6} sm={12} lg={4}>
               <Form.Item
                      name={'CategoryId'}
                      // rules={[noWhitespace(t("dataError"))]}
                  >
                    <Select
                  mode="multiple"
                  placeholder="Değerleri Seçin"
                  className="startegic-select"
                  name='CategoryId'
                >
                  <Option value="1">Dövlətlə tərəfdaşlıq</Option>
                  <Option value="2">Təşkilatlarla tərəfdaşlıq</Option>
                  <Option value="3">Beynalxalq tərafdaşlıqlar</Option>
                  <Option value="4">Digər tərafdaşlıqlar</Option>
                </Select>
                  </Form.Item>
               
               </Col>
               <Col md={5} sm={12} xs ={24}>
               <Form.Item
                      name={''}
                      // rules={[noWhitespace(t("dataError"))]}
                  >
                     <Button 
            type="primary"
            size={"large"}
            className="w-100 f-13"
            htmlType="submit"
          >
            {t("search")}
          </Button>
                  </Form.Item>
              
               </Col>

               <Col md={2} sm={12} xs={24}>
                   <Form.Item
                       name={" "}
                   >
                     <Button type="primary" size={'large'}  onClick={()=>{clearFilter()}} className="w-100" htmlType="submit">
                       <ClearOutlined />
                     </Button>
                   </Form.Item>
                </Col>
              
              </Row>
            </div>
          </Form>
        </Col>
        
   

        <>
          {spin ? (
            <Col xs={24}>
              <div className="flex animated fadeInUp bg-white all-center p-2">
                <Spin size={"large"} />
              </div>
            </Col>
          ) : (
            <Col xs={24}>
              <Table
               loading={spin}
                size="small"
                className="bg-white animated fadeIn"
                columns={initialColumns}
                dataSource={convertColumns(filterData.length !==0 || empty ? filterData : postlist, cols)}
                pagination={{
                  pageSize: 25,
                  current_page: 1,
                }}
              />
            </Col>
          )}
        </>
      </Row>
    </div>
  );
}

export default connect(null, { notify })(StrategicPartnership);
