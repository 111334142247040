import React, { Component } from "react";
import { connect } from "react-redux";
import { notify } from "../../../redux/actions";
import { noWhitespace } from "../../../utils/rules";
import { withTranslation } from "react-i18next";
import {
  Col,
  Input,
  Modal,
  Form,
  Row,
  Button,
  Switch,
  Select,
  InputNumber,
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { aictia } from "../../../const/api";
import JoditEditor from "jodit-react";

class EditPacket extends Component {
  formRef = React.createRef();
  editor = React.createRef();
  mainUrl = "PacketOfPersonal";
  id = this.props.match.params.id;
  constructor(props) {
    super(props);
  }

  state = {
    isMember: false,
    isMeeting: false,
    isRepresent: false,
    isDiscussion: false,
    aboutt: {},
  };

  componentDidMount() {
    if (this.id) {
      this.formRef.current.resetFields();
      let obj = {};

      const getPost = async () => {
        await aictia.get(this.mainUrl + "/" + this.id).then((res) => {
          this.setState({
            isMember: res.data.documentConfirmingMembership,
            isMeeting: res.data.meetingsWithLeaders,
            isDiscussion: res.data.discussionOfProposals,
            isRepresent: res.data.representInTheCommittee,
          });

          res.data.packet_LanguageList.forEach((d) => {
            obj[`title_${d.language}`] = d.name;
            obj[`body_${d.language}`] = d.description;
          });
          obj["Price"] = res.data.price;
          obj["TheRightToPostArticles"] = res.data.theRightToPostArticles;
          obj["TakeAdvantageCertifications"] =
            res.data.takeAdvantageCertifications;
          obj["FreeParticipationInTrainings"] =
            res.data.freeParticipationInTrainings;
          if (this.formRef.current !== null) {
            this.formRef.current.setFieldsValue(obj);
          }
        });
      };

      getPost();
    }
  }

  saveItem = async (values) => {
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    console.log(values);
    let obj = {
      ...values,
      Packet_LanguageList: langs.map((l) => {
        return {
          Language: l.key,
          Name: values[`title_${l.key}`],
          Description: values[`body_${l.key}`],
        };
      }),
      DocumentConfirmingMembership: this.state.isMember,
      MeetingsWithLeaders: this.state.isMeeting,
      RepresentInTheCommittee: this.state.isRepresent,
      DiscussionOfProposals: this.state.isDiscussion,
    };
  
    if (!this.id) {
      await aictia
        .post(this.mainUrl, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    } else {
      obj["id"] = this.props.match.params.id;
      await aictia
        .put(`${this.mainUrl}/${this.props.match.params.id}`, obj)
        .then((res) => {
          this.props.notify("", true);
          this.formRef.current.resetFields();
          window.history.back();
        })
        .catch((err) => {
          this.errorGenerator(err.response.data.error);
        });
    }
  };

  errorGenerator(error) {
    notify("Xəta baş verdi", false);
  }

  render() {
    let { t, getLangs, notify } = this.props;
    const { TextArea } = Input;
    const lang = [
      {
        key: "az",
        id: 1,
      },
      {
        key: "en",
        id: 2,
      },
    ];
    const langs = [
      {
        name: "Azerbaijan",
        key: "az",
        id: 1,
      },
      {
        name: "English",
        key: "en",
        id: 2,
      },
    ];
    return (
      <div>
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
              <div className="page-name">
                <PicCenterOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">
                  Paketi {this.id ? "i redaktə et" : "  əlavə et"}{" "}
                </span>
              </div>
              <Link
                to={{
                  pathname: `/individual-registration`
                }}
              >
                <Button type={"primary"}>{t("cancel")}</Button>
              </Link>
            </div>
          </Col>
          <Col xs={24}>
            <div className={`p-2 animated edit fadeInUp bg-white`}>
              <Form
                ref={this.formRef}
                onFinish={this.saveItem}
                layout="vertical"
              >
                <Row gutter={[55, 35]}>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>Ad</p>
                    {lang.map((l) => (
                      <div key={l.id} className="form-lang">
                        <Form.Item
                          validateTrigger="onChange"
                          name={`title_${l.key}`}
                          rules={[noWhitespace(t("inputError"))]}
                        >
                          <Input className="w-100" />
                        </Form.Item>
                        <span className="input-lang ">{l.key}</span>
                      </div>
                    ))}
                  </Col>
                  <Col xs={24}>
                                        {/*<div className="mb-10">Kontent</div>*/}
                                        {langs.map((l) => (
                                            <div key={l.id} className="form-lang">
                                                <div className="mb-10">
                                                    {l.name}
                                                </div>
                                                <Form.Item
                                                    name={`body_${l.key}`}
                                                    validateTrigger="onChange"
                                                    // getValueFromEvent={(event, editor) => {
                                                    //     const dat = editor.getData();
                                                    //     return dat;
                                                    // }}
                                                    rules={[noWhitespace(t("inputError"))]}
                                                >
                                             
                                                    <JoditEditor
                                                        ref={this.editor}
                                                        value={this.state.aboutt[`about_${l.key}`]}
                                                        tabIndex={1} // tabIndex of textarea
                                                    />
                                                </Form.Item>
                                            </div>
                                        ))}
                                    </Col>





                  <Col md={12} sm={12} xs={24}>
                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isMember}
                        onChange={() => {
                          this.setState({ isMember: !this.state.isMember });
                        }}
                      />
                      <span className="mr-15">
                        {" "}
                        AİKTSA Üzvlüyü Təsdiqləyən sənəd:
                      </span>
                      <span className={this.state.isMember ? "green" : "red"}>
                        {this.state.isMember ? "+" : "-"}
                      </span>
                    </div>
                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isMeeting}
                        onChange={() => {
                          this.setState({ isMeeting: !this.state.isMeeting });
                        }}
                      />
                      <span className="mr-15">Rəhbərliklə iştirak:</span>
                      <span className={this.state.isMeeting ? "green" : "red"}>
                        {this.state.isMeeting ? "+" : "-"}
                      </span>
                    </div>

                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isRepresent}
                        onChange={() => {
                          this.setState({
                            isRepresent: !this.state.isRepresent,
                          });
                        }}
                      />
                      <span className="mr-15">
                        AİKTSA-nın Təşkilat Komitəsində təmsil olunmaq hüququ:
                      </span>
                      <span
                        className={this.state.isRepresent ? "green" : "red"}
                      >
                        {this.state.isRepresent ? "+" : "-"}
                      </span>
                    </div>

                    <div className="flex mb-15">
                      <Switch
                        className={"mr-15"}
                        checked={this.state.isDiscussion}
                        onChange={() => {
                          this.setState({
                            isDiscussion: !this.state.isDiscussion,
                          });
                        }}
                      />
                      <span className="mr-15">
                        AİKTSA-nın təkliflərinin müzakirəsində iştirak
                      </span>
                      <span
                        className={this.state.isDiscussion ? "green" : "red"}
                      >
                        {this.state.isDiscussion ? "+" : "-"}
                      </span>
                    </div>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın aylıq korperativ üzvlük qiymətləri
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"Price"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      {" "}
                      www.aictia.org saytında məqalə və elmi araşdırmaları
                      yerləşdirmək hüququ
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"TheRightToPostArticles"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın təltiflərindən və sertifikatlaşdırmalarından
                      yararlanmaq
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"TakeAdvantageCertifications"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={12} xs={24}>
                    <p className={"mb-15"}>
                      AİKTSA-nın təlimlərində ödənişsiz iştirak
                    </p>

                    <Form.Item
                      validateTrigger="onChange"
                      name={"FreeParticipationInTrainings"}
                      rules={[noWhitespace(t("inputError"))]}
                    >
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </Col>
                  <Col xs={24}>
                    <div className={"flex"}>
                      <Button className={"mr-15"} htmlType="submit">
                        {t("save")}
                      </Button>
                      <Link
                        to={{
                          pathname: `/individual-registration`
                        }}
                      >
                        <Button type={"primary"}>{t("cancel")}</Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>

        <Modal
          visible={this.state.previewVisible}
          title={false}
          footer={null}
          onCancel={() => {
            this.setState({ previewVisible: false });
          }}
        >
          <img
            alt="example"
            style={{ width: "100%" }}
            src={this.state.previewImage}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = ({ langs }) => {
  return {
    langs: langs,
  };
};

const exp = withTranslation()(EditPacket);
export default connect(mapStateToProps, { notify })(exp);
