import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import aictia from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { whiteSpace } from "../../../../utils/rules";

const Branch = (props) => {
    const mainUrl = 'Branch'
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [spin, setSpin] = useState(false);
    const [editing, setEditing] = useState(null);
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "name", value: t("name"), con: true },
        { key: "id", value: "", con: false },
    ];
    const nameInput = useRef();

    // props
    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title: t("name"),
            key: "2",
            dataIndex: "name",
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const setEditingObject = async (i) => {
        setEditing(i);
        await aictia.get(mainUrl + `/${i}`).then((res) => {
            const names = res.data.branch_Languages
            let obj = {};
            names.forEach((item) => {
                obj[`name_${item.language}`] = item.name;
            });
            form.setFieldsValue(obj);
        });
    };

    const cancelEditing = () => {
        setEditing(null);
        form.resetFields();
    };

    const deletePosition = async (i) => {
        await aictia
            .delete(`${mainUrl}/${i}`)
            .then(() => {
                // description
                notify("silindi", true);
                getPositions();
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let langs = ["az", "en"];
        let obj = {
            Branch_Languages: langs.map((lang, index) => {
                return { Language: lang, Name: values[`name_${lang}`] };
            }),
        };
        if (!editing) {
            await aictia
                .post(mainUrl, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj["id"] = editing;
            await aictia
                .put(`${mainUrl}/${editing}`, obj)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        setSpin(true);
        await aictia.get(mainUrl).then((res) => {
            setSpin(false);
            setPositions(
                res.data.map((p, index) => {
                    return {
                        key: index + 1,
                        ...p,
                        index: index + 1,
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPositions();
    }, [t]);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border animated fadeInDown p-2 mt-0 bg-white">
                    <UnorderedListOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Sahələr</span>
                </div>
            </Col>
            <Col lg={12} xs={24}>
                <Table
                    loading={spin}
                    size="small"
                    className="bg-white animated fadeInLeft"
                    columns={columns}
                    dataSource={convertColumns(positions, cols)}
                    pagination={{
                        pageSize: 10,
                        current_page: 1,
                        total: positions.length,
                    }}
                />
            </Col>
            <Col lg={12} xs={24}>
                <Card title={t("addTo")} className={"animated fadeInRight"}>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <p className="mb-5">Sahənin adı</p>
                        <div className="form-lang">
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name="name_az"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <Input ref={nameInput} />
                            </Form.Item>
                            <div className="input-lang">az</div>
                        </div>

                        <div className="form-lang">
                            <Form.Item
                                className="mb-5"
                                validateTrigger="onChange"
                                name="name_en"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <Input ref={nameInput} />
                            </Form.Item>
                            <div className="input-lang">en</div>
                        </div>

                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            <Button htmlType="submit">{t("save")}</Button>
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

const mapStateToProps = ({ langs }) => {
    return {
        langs: langs,
    };
};

export default connect(mapStateToProps, { notify })(Branch);