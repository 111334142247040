import React , {useEffect , useState} from "react";
import { Col, Row, Table } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../utils/columnconverter";
import aictia from "../../../const/api";

const PersonCorpoDetails = (props) => {
  const mainUrl = "Account/Cooperative";
  const { t } = useTranslation();
  const [purchases , setPurchases] = useState({});
  const [data , setData] = useState([]);
  const ownership = [
    { ower: 1, code: "Mikro" },
    { ower: 2, code: "Kiçik" },
    { ower: 3, code: "Orta" },
    { ower: 4, code: "İri" },
];

const items = [
    { item: 1, code: "MMC" },
    { item: 2, code: "QSC" },
    { item: 3, code: "ASC" },
];
  useEffect(()=>{
    console.log(props.id);
    const getData = async () => {
        try {
            const res = await aictia.get(mainUrl);
            const arr = res?.data?.filter((elem) => elem.id === Number(props.id));
            setData(
                arr.map((d, index) => {
                    return {
                        ...d,
                        country: d.country.name,
                        nameOfCompany: d.nameOfCompany + " " + items.filter((i) => i.item == d.typeOfCompany)[0]?.code,
                        packetOfCooperative: d.packetOfCooperative.name,
                        dateOfIncorporationCompany: moment(d.date).format("DD.MM.YYYY"),
                        typeOfActivity: d.typeOfActivity.name,
                        businessEntity: ownership.filter((i) => i.ower === d.businessEntity)[0]?.code,
                        key: index + 1
                    };
                })
            );

        } catch (error) {
            console.log(error);
        }
    };

      getData()
    
  }, [ props.id])

console.log(data);
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col md={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td>Ölkə:</td>
                <td>{data[0]?.country}</td>
              </tr>
              <tr>
                <td>Şirkətin adı:</td>
                <td>{data[0]?.nameOfCompany}</td>
              </tr>
              <tr>
                <td>VÖEN:</td>
                <td>
                 {data[0]?.tin}
                </td>
              </tr>
              <tr>
                <td>Fəaliyyət növü:</td>
                <td>
                  {data[0]?.typeOfActivity}
                </td>
              </tr>
              <tr>
                <td>Tarix</td>
                <td>{data[0]?.dateOfIncorporationCompany}</td>
              </tr>
              <tr>
                <td>Əməkdaş sayı</td>
                <td>{data[0]?.countOfEmployees}</td>
              </tr>
              <tr>
                <td>Paket</td>
                <td>{data[0]?.packetOfCooperative}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col md={12}>
          <table className="customtable">
            <tbody>
              <tr>
                <td>Sahibkarlıq subyekti:</td>
                <td>{data[0]?.businessEntity}</td>
              </tr>
              <tr>
                <td>Ünvan:</td>
                <td> {data[0]?.address}</td>
              </tr>
              <tr>
                <td>Telefon:</td>
                <td> {data[0]?.phone}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td> {data[0]?.email}</td>
              </tr>
              <tr>
                <td>Səlahiyyətli şəxs:</td>
                <td> {data[0]?.nameOfAuthorizedPerson}</td>
              </tr>
                <tr>
                <td>Səlahiyyətli şəxsin FİN-i</td>
                <td>{data[0]?.piNofAuthorizedPerson}</td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>{
                    data[0]?.status === 1 ? <span style={{
                        fontSize: '11px',
                        backgroundColor: '#E49F33',
                    }}
                        className="statusBox">Gözləmədə</span> : data[0]?.status === 2 ? <span style={{
                            fontSize: '11px',
                            backgroundColor: "#7FBFA4",
                        }}
                            className="statusBox">Təsdiq</span> : data[0]?.status === 3 ? <span style={{
                                fontSize: '11px',
                                backgroundColor: "#D24C41",
                            }}
                                className="statusBox">imtina</span> : null
                    }
           
                </td>
              </tr>
            </tbody>
          </table>
        </Col>

      </Row>
    </div>
  );
};

export default PersonCorpoDetails;