import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import moment from "moment";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Spin,
    Popconfirm,
    Switch,
    DatePicker,
    Select,
    Form
} from "antd";
import {
    PicCenterOutlined,
    DeleteFilled,
    EditFilled,
    ClearOutlined
} from "@ant-design/icons";
import { aictia } from "../../../../const/api";
import { notify } from "../../../../redux/actions";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import MediaAgency from "../../Admin/MediaAgency";

const { Option } = Select;
function News(props) {
    let mainUrl = 'news'
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [endDate, setEndDate] = useState(undefined);
    const [startDate, setStartDate] = useState(undefined);
    const [parcelCategoryId, setParcelCategoryId] = useState(0);
    const [parcelMediaId, setParcelMediaId] = useState(0);
    const [parcelShowId, setParcelShowId] = useState(0);
    const [count, setCount] = useState(0)
    const [postList, setPostList] = useState([]);
    const [filterPage, setFilterPage] = useState(0);
    const perPage = 10;
    const [page, setPage] = useState(0);
    const [media, setMedia] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [empty, setEmpty] = useState(false);
    const [filter, setFilter] = useState(false);
    const [spin, setSpin] = useState(false);
    const { notify } = props;
    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "title", value: t("name"), con: true },
        { key: "image", value: "Logo", con: true },
        { key: "hideShowStatus", value: "Status", con: true },
        { key: "newsCategory", value: "Kateqoriya", con: false },
        { key: "mediaAgency", value: "Media Agentliyi", con: false },
        { key: "publishDate", value: "Tarix", con: true },
        { key: "id", value: "", con: false },
        { key: "showOnMainPage", value: "Main", con: true },

    ];
    const [newsFilter, setNewsFilter] = useState([])
    const [mediaFilter, setMediaFilter] = useState([])
    const clearFilter = () => {
        form.resetFields()
        setFilterData([])
        setEmpty(false)
        setFilter(false)
    }
    const changeMain = async (i, id) => {
        const obj = { id: id, "ShowOnMainPage": !i }
        await aictia.put(mainUrl + '/ShowOnMainPage/' + id, obj)
            .then((res) => {
                const filter = media.map((item) =>
                    item.id === id ? { ...item, showOnMainPage: !i } : item
                )
                const filterNew = filterData.map((item) =>
                    item.id === id ? { ...item, showOnMainPage: !i } : item
                )
                setMedia(filter);
                setFilterData(filterNew);
            })
            .catch((err) => {
                console.log(err);
                notify("Sadəcə redaktə edə bilərsiniz.", false);
            });
    }
    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: "Logo",
            dataIndex: "image",
            key: "9",
            render: (i) => {
                return i ? <img className={"tableImage"} src={i.url} alt="" /> : "";
            },
        },
        {
            title: t("name"),
            dataIndex: "title",
            key: "2",
        },
        {
            title: 'Kateqoriya',
            dataIndex: "newsCategory",
            key: "4",
            render: (i) => {
                return i.name
            },
        },
        {
            title: 'Media Agentliyi',
            dataIndex: "mediaAgency",
            key: "4",
            render: (i) => {
                return i?.name ? i?.name : 'Seçilməyib'
            },
        },
        {
            title: 'Tarix',
            dataIndex: "publishDate",
            key: "10",
            render: (i) => {
                return moment(i).format('YYYY-MM-DD')
            },
        },
        {
            title: "Status",
            dataIndex: "hideShowStatus",
            key: "6",
            render: (i) => {
                return i ? (
                    <span className="green">Aktiv</span>
                ) : (
                    <span className="red">Deaktiv</span>
                );
            },
        },
        {
            title: "Main",
            dataIndex: "showOnMainPage",
            key: "6",
            render: (i, index) => {
                return (
                    <div className="flex mb-15">
                        <Switch
                            className={"mr-15"}
                            checked={i}
                            onChange={() => {
                                changeMain(i, index.id);
                            }}
                        />
                    </div>
                )
            },
        },
        {
            title: "",
            dataIndex: "id",
            key: "9",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Link
                                to={{
                                    pathname: `/news/edit/${i}`,
                                    state: {
                                        locales: media.find((p) => {
                                            return p.id == i;
                                        })?.locales,
                                    },
                                }}
                            >
                                <Button className="border-none" type="text" shape="circle">
                                    <EditFilled />
                                </Button>
                            </Link>
                        </Tooltip>
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePost(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>
                    </div>
                );
            },
        },
    ];

    const deletePost = async (i) => {
        if (i === 0 || i) {
            await aictia
                .delete(`${mainUrl}/${i}`)
                .then((res) => {
                    const newFilterData = media.filter((post) => post.id !== i);
                    setMedia(newFilterData);
                    const newFilterDataTwo = filterData.filter((post) => post.id !== i);
                    setFilterData(newFilterDataTwo);
                    notify("silindi", true);
                    console.log('silindi');
                })
                .catch((res) => {
                    notify(res.err, false);
                });
        }
    };

    const getPostList = () => {
        setSpin(true)
        aictia.get(mainUrl + `?PerDataCount=${page}&GetDataCount=${perPage}`)
            .then(res => {
                res.data && setSpin(false);
                setMedia([...media, ...res.data.data]);
                setCount(res.data.data_count);
            })
    };
    const onSearch = (values) => {
        let obj = {}
        setFilterPage(0)
        setFilter(true)
        setSpin(true)
        if (values.EndDate) { // endDate varsa
            setEndDate(moment(values.EndDate._d).format('YYYY-MM-DD'))
            obj['EndDate'] = moment(values.EndDate._d).format('YYYY-MM-DD')
        }
        if (values.StartDate) { // endDate varsa
            setStartDate(moment(values.StartDate._d).format('YYYY-MM-DD'))
            obj['StartDate'] = moment(values.StartDate?._d).format('YYYY-MM-DD')
        }
        if (values.CheckCategory) { // endDate varsa
            setParcelCategoryId(values.CheckCategory)
            obj['CategoryId'] = values.CheckCategory
        }
        if (values.CheckMediaAgency) { // endDate varsa
            setParcelMediaId(values.CheckMediaAgency)
            obj['MediaAgencyId'] = values.CheckMediaAgency
        }
        if (values.HideShowStatus) { // endDate varsa
            setParcelShowId(values.HideShowStatus)
            obj['HideShowStatus'] = values.HideShowStatus
        }
        if (Object.keys(obj).length === 0 && obj.constructor === Object) {
            console.log('obj is empty');
            return;
        }
        if (obj !== {}) {
            console.log(obj);
            aictia.get(mainUrl + `?PerDataCount=${filterPage}&GetDataCount=${perPage}`, { params: obj }).then((res) => {
                if (res.data.data.length === 0) {
                    setEmpty(true)
                } else {
                    setEmpty(false)
                }
                setFilterData([...res.data.data]);
                setPostList([...res.data.data]);
                setCount(res.data.data_count);
                setSpin(false);
            });
        }
    }
    const filterDataPagination = () => {
        setSpin(true)
        let obj = {}
        if (endDate) { // endDate varsa
            obj['EndDate'] = moment(endDate).format('YYYY-MM-DD')
        }
        if (startDate) { // startdate varsa
            obj['StartDate'] = moment(startDate).format('YYYY-MM-DD')
        }
        if (parcelCategoryId) { // category varsa
            obj['CategoryId'] = parcelCategoryId
        }
        if (parcelMediaId) { // category varsa
            obj['MediaAgencyId'] = parcelMediaId
        }
        if (parcelShowId) { // category varsa
            obj['HideShowStatus'] = parcelShowId
        }
        console.log(obj);
        aictia.get(mainUrl + `?PerDataCount=${filterPage}&GetDataCount=${perPage}`, { params: obj }).then((res) => {
            console.log(res.data.data);
            setFilterData([...filterData, ...res.data.data]);
            setSpin(false);
            setPostList([...res.data.data]);
        });
    };
    const getNewsCategory = () => {
        aictia.get('newscategory').then((res) => {
            setNewsFilter(res.data);
        });
    }
    const getMediaAgency = () => {
        aictia.get('MediaAgency').then((res) => {
            setMediaFilter(res.data);
        });
    }
    useEffect(() => {
        getPostList();
        getNewsCategory()
        getMediaAgency()
    }, [t, page]);
    useEffect(() => {
        filter && filterDataPagination();
    }, [filterPage]);


    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <PicCenterOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Xəbərlər</span>
                        </div>
                        <div>
                            <Link
                                to={{
                                    pathname: `news/create`,
                                    state: { locales: null },
                                }}
                            >
                                <Button type={"primary"}>Əlavə et</Button>
                            </Link>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Form onFinish={onSearch} layout="vertical" form={form}>
                        <div className="commontask bg-white px-2 pt-15">
                            <Row className={'mt-5'} gutter={[16, 8]}>
                                <Col md={3} sm={8} xs={20}>
                                    <Form.Item
                                        name={'StartDate'}
                                    >
                                        <DatePicker
                                            format={"DD-MM-YYYY"}
                                            placeholder={'Tarixdən'}
                                            className="w-100"
                                            onChange={() => setFilterPage(0)}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col md={3} sm={8} xs={20}>
                                    <Form.Item
                                        name={'EndDate'}
                                    >
                                        <DatePicker
                                            format={"DD-MM-YYYY"}
                                            placeholder={'Tarixə'}
                                            className="w-100"
                                            onChange={() => setFilterPage(0)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={3} sm={8} xs={20}>
                                    <Form.Item
                                        name={"CheckCategory"}
                                    >
                                        <Select placeholder='Kateqoriya' onChange={() => setFilterPage(0)}>
                                            {newsFilter.map((pc, index) => {
                                                return (
                                                    <Option key={index} value={pc.id}>
                                                        {pc.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={3} sm={8} xs={20}>
                                    <Form.Item
                                        name={"CheckMediaAgency"}
                                    >
                                        <Select placeholder='Media Agentliyi' onChange={() => setFilterPage(0)}>
                                            {mediaFilter.map((pc, index) => {
                                                return (
                                                    <Option key={index} value={pc.id}>
                                                        {pc.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={3} sm={8} xs={20}>
                                    <Form.Item
                                        name={"HideShowStatus"}
                                    >
                                        <Select placeholder='Status' onChange={() => setFilterPage(0)}>
                                            <Option value={'true'} index={1}>
                                                Aktiv
                                            </Option>
                                            <Option value={'false'} index={2}>
                                                Deaktiv
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {/* <Col md={5} sm={12} xs={24}>
                   <Form.Item
                       name={"Admin"}
                       // rules={[noWhitespace(t("inputError"))]}
                   >
                     <Select placeholder='Admin'>
                     <Option key={100000} value={0}>
                  Admin
                </Option>
                     </Select>
                   </Form.Item>
                </Col> */}
                                <Col md={4} sm={12} xs={24}>
                                    <Form.Item
                                        name={" "}
                                    >
                                        <Button type="primary" size={'large'} className="w-100 f-13" htmlType="submit">
                                            {t("search")}
                                        </Button>
                                    </Form.Item>
                                </Col>

                                <Col md={2} sm={12} xs={24}>
                                    <Form.Item
                                        name={" "}
                                    >
                                        <Button type="primary" size={'large'} onClick={() => { clearFilter() }} className="w-100" htmlType="submit">
                                            <ClearOutlined />
                                        </Button>
                                    </Form.Item>
                                </Col>

                            </Row>
                        </div>
                    </Form>
                </Col>
                <>
                    {spin ? (
                        <Col xs={24}>
                            <div className="flex animated fadeInUp bg-white all-center p-2">
                                <Spin size={"large"} />
                            </div>
                        </Col>
                    ) : (
                        <Col xs={24}>
                            <Table
                                size="small"
                                className="bg-white animated fadeIn"
                                columns={initialColumns}
                                dataSource={convertColumns(filterData.length !== 0 || empty ? filterData : media, cols)}
                                pagination={{
                                    pageSize: 1000,
                                    current_page: 1,
                                }}
                            />
                        </Col>
                    )}
                </>
            </Row>
            {filterData.length === 0 && media.length < count && !empty &&
                <Button type={"primary"} onClick={() => {
                    setPage(page + perPage);
                }}>Daha çox yükləyin</Button>}
            {filterData.length !== 0 && postList.length > 0 && !empty &&
                <Button type={"primary"} onClick={() => {
                    setFilterPage(filterPage + perPage);
                }}>Daha çox yükləyin</Button>}
        </div>
    );
}


export default connect(null, { notify })(News);