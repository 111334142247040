import React from "react";
import { aictia } from "../../../const/api";
import { useParams, Link } from "react-router-dom";
import {
  Col,
  Row,
  Button
} from "antd";
import { PicCenterOutlined } from "@ant-design/icons";

function Detail() {
  const id = useParams().id;
  const mainUrl = "PacketOfPersonal";
  const [data, setData] = React.useState();

  const getData = async () => {
    try {
      const res = await aictia.get(mainUrl)
      const arr = res.data.filter((elem) => elem.id === Number(id))
      setData(arr);
      
    } catch (error) {
      console.log(error);
    }
  };
  
  React.useEffect(() => {
    getData();
  }, []);

  const headers = [
    {
      az: "AİKTSA üzvlüyü təsdiqləyən sertifikat",
      eng: "documentConfirmingMembership",
    },
    {
      az: "Biznes-forum və dəyirmi masa çərçivəsində AİKTSA üzvlərinin dövlət qurumlarının rəhbər və nümayəndəlikləri ilə görüşlərində iştirak",
      eng: "meetingsWithLeaders",
    },
    {
      az: "AİKTSA-nın Təşkilat Komitəsində təmsil olunmaq hüququ",
      eng: "representInTheCommittee",
    },
    {
      az: "www.aictia.org saytında İKT sahəsində müəllifi olduqları məqalə və elmi araşdırmaları yerləşdirmək hüququ",
      eng: "theRightToPostArticles",
    },
    {
      az: "AİKTSA-nın təkliflərinin müzakirəsində iştirak",
      eng: "discussionOfProposals",
    },
    {
      az: "AİKTSA-nın təltiflərindən və sertifikatlaşdırmalarından yararlanmaq",
      eng: "takeAdvantageCertifications",
    },
    {
      az: "AİKTSA-nın təlimlərində ödənişsiz iştirak",
      eng: "freeParticipationInTrainings",
    },
    {
      az: "AİKTSA-nın aylıq korperativ üzvlük qiymətləri",
      eng: "price",
    },
  ];
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <PicCenterOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Ətraflı</span>
            </div>
            <Link
              to={{
                pathname: `/individual-registration`,
                // state: { locales: null, name: this.props.location.state.name },
              }}
            >
              <Button type={"primary"}>Çıxış</Button>
            </Link>
          </div>
        </Col>
      </Row>

      <div className="membershipTable">
        <table className="table">
          <thead>
            <tr>
              <th>Üstünlüklər</th>
              {data?.map((item, index) => (
                <th key={index}>{item?.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {headers.map((item, index) => (
              <tr key={index}>
                <td className="statik">
                  <p>{item.az}</p>
                </td>
                {data?.map((backItem) => {
                  let tableRow = Object.keys(backItem);
                  let tableData = Object.values(backItem);
                  return tableRow.map((tableItem, tableIndex) => {
                    if (tableItem === item.eng) {
                      if (tableItem === "theRightToPostArticles" || tableItem === "freeParticipationInTrainings") {
                        return (
                          <td>{` aylıq ${tableData[tableIndex]} dəfə`}</td>
                        );
                      }
                      if (tableItem === "takeAdvantageCertifications") {
                        return (
                          <td>{` illik ${tableData[tableIndex]} dəfə`}</td>
                        );
                      }
                      if (tableItem === "price") {
                        return (
                          <td>
                            <span className="price">
                              {`${tableData[tableIndex]} AZN/ay`}
                            </span>
                          </td>
                        );
                      }
                      return (
                        <td>
                          {tableData[tableIndex] === false
                            ? "-"
                            : tableData[tableIndex] === true
                            ? "+"
                            : tableData[tableIndex]}
                        </td>
                      );
                    }
                  });
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </>
  );
}

export default Detail;
