import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Button,
    Tooltip,
    Menu,
    Col,
    Row,
    Spin,
    Modal,
    Table,
    Form,
    Input,
    Select
} from "antd";
import { Dropdown, Switch } from 'antd';
import {
    ArrowRightOutlined,
    PicCenterOutlined
} from "@ant-design/icons";
import { notify } from "../../../redux/actions";
import { connect } from "react-redux";
import { aictia } from "../../../const/api"
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { convertColumns } from "../../../utils/columnconverter";
import { noWhitespace } from "../../../utils/rules";
const { Option } = Select
const { TextArea } = Input;
function Startup(props) {
    let mainUrl = 'Startup'
    const [postlist, setPostList] = useState([]);
    const [spin, setSpin] = useState(false);
    const [reason, setReason] = useState([]);
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "memberName", value: "Üzvün adı", con: true },
        { key: "type", value: "Startapın statusu", con: false },
        { key: "memberType", value: 'Üzvün növü', con: false },
        { key: "branchName", value: 'Sahə', con: false },
        { key: "status", value: 'Status', con: false },
        { key: "hideShowStatus", value: 'Görünmə statusu', con: false }
    ];
    const [visibleViewWorker, setVisibleViewWorker] = useState(false);
    const [viewIndex, setViewIndex] = useState(null);
    const approveAccount = async (i) => {
        await aictia.put(`Startup/Approve/${i}`, '')
            .then((res) => {
                getPostList()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const hideShowStartup = async (i,id) => {
        console.log(i);
        const obj = { Id: id, "HideShowStatus": !i}
        await aictia.put(`Startup/HideShow/${id}`,obj)
            .then((res) => {
                getPostList()
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const viewWorker = (i) => {
        setViewIndex(i);
        setVisibleViewWorker(true);
    };
    const reasonOfReject = async (i) => {
        await aictia.get(`ReasonOfReject`)
            .then((res) => {
                console.log(res.data);
                setReason(res.data)
            })
            .catch((err) => {
                console.log(err);
            });
    } 
    const savePosition = async (values) => {
            let obj = {}
            obj = {
                "Id": viewIndex,
                "Note": values.Note,
                "ReasonOfRejectId": values.ReasonOfRejectId
            }
            await aictia
            .put(`Startup/Reject/${viewIndex}`, obj)
            .then((res) => {
                notify("", true);
                getPostList()
            })
            .catch((err) => {
                notify(err.response, false);
            });

        console.log(values);
    };
    const startupType = [
        { id: 1, name: 'Demo' },
        { id: 2, name: 'Betatest' },
        { id: 3, name: 'Açıq istifadə' },
        { id: 4, name: 'Feature' },
        { id: 5, name: 'Təşəbbüs' }
    ]
    const initialColumns = [
        {
            title: "#",
            dataIndex: "tableIndex",
            key: "1",
            width: 60,
        },
        {
            title: "Üzvün adı",
            dataIndex: "memberName",
            key: "2",
        },
        {
            title: "Startapın adı",
            dataIndex: "name",
            key: "11",
        },
        {
            title: 'Startupın statusu',
            dataIndex: "type",
            key: "3",
            render: (i) => {
                const startup = startupType.find(s => s.id === i);
                return <span>{startup ? startup.name : ""}</span>;
            }
        },
        {
            title: 'Üzvün növü',
            dataIndex: "memberType",
            key: "4",
            render: (i) => {
                if (i === 2) {
                    return <span>Korporativ</span>;
                }
                if (i === 1) {
                    return <span>Fərdi</span>;
                }

            }
        },
        {
            title: 'Görünmə statusu',
            dataIndex: "hideShowStatus",
            key: "9",
            render: (i,index) => {
                return(
                    <div className="flex mb-15">
                    <Switch
                        className={"mr-15"}
                        checked={i}
                        disabled={index.status !== 3}
                        onChange={() =>{
                            hideShowStartup(i,index.id);
                        }}
                    />                    
                </div>
                )
            },
        },
        {
            title: 'Status',
            dataIndex: "status",
            key: "6",
            render: (i) => {
                if (i === 2) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: '#E49F33',
                    }}
                        className="statusBox" >Göndərildi</span>;
                }
                if (i === 3) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: "#7FBFA4",
                    }}
                        className="statusBox" >Təsdiq</span>;
                }
                if (i === 4) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: "#D24C41",
                    }}
                        className="statusBox">İmtina</span>;
                }
                if (i === 5) {
                    return <span style={{
                        fontSize: '11px',
                        backgroundColor: "#bbb",
                    }}
                        className="statusBox">Dayandırılıb</span>;
                }
            }
        },

        {
            title: 'Sahə',
            dataIndex: "branchName",
            key: "7",

        },
        {
            title: "",
            dataIndex: "id",
            key: "",
            render: (i,data) => {
                console.log(data);
                return (
                    <div className="flex flex-end">
                        <Tooltip
                            className="ml-5"
                            title={t("operations")}
                            placement="leftBottom"
                        >
                            <Dropdown
                                overlay={
                                    <Menu>
                                        {
                                            data.status === 2 &&
                                        <Menu.Item>
                                            <div className="flex f-14 flex-align-center pr-1 pl-1" onClick={() => approveAccount(i)}>
                                                <span>Təsdiq</span>
                                            </div>
                                        </Menu.Item>
                                        }
                                        {
                                            data.status === 2 &&
                                            <Menu.Item>
                                            <div className="flex f-14 flex-align-center pr-1 pl-1" onClick={() => viewWorker(i)}>
                                                <span>İmtina</span>
                                            </div>
                                        </Menu.Item>
                                        }
                                        <Menu.Item >
                                            <Link
                                                to={{
                                                    pathname: `/startup/detail/${i}`
                                                }}
                                            >
                                                Ətraflı bax
                                            </Link>
                                        </Menu.Item>
                                    </Menu>
                                }
                                placement="bottomRight"
                                arrow
                            >
                                <Button shape="circle" className="border-none">
                                    <ArrowRightOutlined />
                                </Button>
                            </Dropdown>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const getPostList = () => {
        setSpin(true);
        aictia.get(mainUrl).then((res) => {
            res.data && setSpin(false);
            setPostList(
                res.data.map((d, index) => {
                    return {
                        ...d,
                        key: index + 1,
                        index,
                        tableIndex: index + 1
                    };
                })
            );
        });
    };

    useEffect(() => {
        getPostList();
        reasonOfReject();
    }, []);

    return (
        <Row gutter={[10, 10]}>
            <Col xs={24}>
                <div className="border flex-between page-heading flex p-2 mt-0 bg-white">
                    <div className="page-name">
                        <PicCenterOutlined className="f-20 mr5-15" />
                        <span className="f-20 bold">Startuplar</span>
                    </div>
                </div>
            </Col>

            <>
                {spin ? (
                    <Col xs={24}>
                        <div className="flex animated fadeInUp bg-white all-center p-2">
                            <Spin size={"large"} />
                        </div>
                    </Col>
                ) : (
                    <Col xs={24}>
                        <Table
                            size="small"
                            className="bg-white animated fadeIn"
                            columns={initialColumns}
                            dataSource={convertColumns(postlist, cols)}
                            pagination={{
                                pageSize: 25,
                                current_page: 1,
                            }}
                        />
                    </Col>
                )}
            </>
            <Modal
                title={'İmtina səbəbi'}
                centered
                className="addTaskModal"
                visible={visibleViewWorker}
                onOk={() => setVisibleViewWorker(false)}
                onCancel={() => setVisibleViewWorker(false)}
                footer={[]}
            >
                <div>
                    <Col lg={24} xs={24}>
                        <Form layout="vertical" onFinish={savePosition} form={form}>
                            <p className={"mt-15 mb-5"}>İmtina səbəbi kateqoriyası</p>
                            <Form.Item
                                name={"ReasonOfRejectId"}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    showSearch
                                    className={'w-100'}
                                    notFoundContent={null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                >

                                    {
                                        reason.map((w, i) => {
                                            return w.type === 1 && (
                                                <Option key={i} value={w.id}>
                                                    {w.name}
                                                </Option>
                                            );

                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <p className={"mt-15 mb-5"}>Qeyd</p>
                            <Form.Item
                                name={"Note"}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                                className="rejectNote"
                            >
                                <TextArea rows={2} />
                            </Form.Item>
                            <div key="0" className="modalButtons m-5">
                            <Button htmlType="submit" onClick={() => setVisibleViewWorker(false)}>{t("save")}</Button>
                            </div>
                        </Form>
                    </Col>

                </div>
            </Modal>
        </Row>
    );
}

export default connect(null, { notify })(Startup)
