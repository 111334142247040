import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  AuditOutlined,
  PictureOutlined,
  ContactsOutlined,
  HomeOutlined,
  FileDoneOutlined,
  FundProjectionScreenOutlined,
  DropboxOutlined 
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";

const { SubMenu } = Menu;

const MenuList = () => {
  const { t } = useTranslation();
  const [openKeys, setOpenKeys] = useState([])

  const rootSubmenuKeys = ["11", "21", "31", "41", "51", "61", "71", "81", "91", "101"];


  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenChange = (openKeysList) => {
    const latestOpenKey = openKeysList.find(
      (key) => openKeys.indexOf(key) === -1
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(openKeysList);
    } else {
      const opens = latestOpenKey ? [latestOpenKey] : [];
      setOpenKeys(opens);
    }
  };

  return (
    <Menu
      openKeys={openKeys}
      // inlineCollapsed={collapsed}
      mode="inline"
      theme="light"
      onOpenChange={onOpenChange}
      className="menu-ul"
    >
      {/* {perms.administrator.perms.read && (
        <SubMenu
          key="11"
          title={
            <span>
              <FundOutlined />
              <span>{t("admin")}</span>
            </span>
          }
        >
          {perms.administrator.subs.positions.perms.read && (
            <Menu.Item key="12">
              <Link to={`/admin/positions`}>
                <span>{t("positions")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.permissions.perms.read && (
            <Menu.Item key="13">
              <Link to={`/admin/permissions`}>
                <span>{t("permissions")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.users.perms.read && (
            <Menu.Item key="14">
              <Link to={`/admin/users`}>
                <span>{t("users")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.warehouseSettings.perms.read && (
            <Menu.Item key="15">
              <Link to={`/admin/storage-settings`}>
                <span>{t("storageSettings")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.landParcels.perms.read && (
            <Menu.Item key="16">
              <Link to={`/admin/areas`}>
                <span>{t("areas")}</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="16.1">
            <Link to={`/admin/equipment`}>
              <span>Texnika</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="16.1">
            <Link to={`/admin/technique-spare-part`}>
              <span>Texnika ehtiyyat hissələri</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="16.2">
            <Link to={`/admin/stations`}>
              <span>Metroloji stansiyalar</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="16.3">
            <Link to={`/admin/silo`}>
              <span>Silolar</span>
            </Link>
          </Menu.Item>
          {perms.administrator.subs.typesAndConditions.perms.read && (
            <Menu.Item key="17">
              <Link to={`/admin/type-and-conditions`}>
                <span>{t("typeAndConditions")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.clientAndConsumers.perms.read && (
            <Menu.Item key="18">
              <Link to={`/admin/client-and-consumers`}>
                <span>{t("clientAndConsumers")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.administrator.subs.todos.perms.read && (
            <Menu.Item key="19">
              <Link to={`/admin/todos`}>
                <span>{t("todos")}</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {perms.hr.perms.read && (
        <SubMenu
          key="21"
          title={
            <span>
              <SnippetsOutlined />
              <span>{t("humanResources")}</span>
            </span>
          }
        >
          {perms.hr.subs.workers.perms.read && (
            <Menu.Item key="22">
              <Link to={`/human-resources/workers`}>
                <span>Əməkdaşlar</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {perms.warehouse.perms.read && (
        <SubMenu
          key="31"
          title={
            <span>
              <AimOutlined />
              <span>{t("warehouse")}</span>
            </span>
          }
        >
          {perms.warehouse.subs.demands.perms.read && (
            <Menu.Item key="32">
              <Link to={`/warehouse/demands`}>
                <span>{t("demands")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.warehouse.subs.purchases.perms.read && (
            <Menu.Item key="33">
              <Link to={`/warehouse/purchases`}>
                <span>{t("purchases")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.warehouse.subs.purchasesOnWait.perms.read && (
            <Menu.Item key="34">
              <Link to={`/warehouse/purchases-on-wait`}>
                <span>{t("purchasesOnWait")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.warehouse.subs.drugAndFertilizers.perms.read && (
            <Menu.Item key="35">
              <Link to={`/warehouse/drug-and-fertilizers`}>
                <span className={stock && stock.medicalStock > 0 ? "red" : ""}>
                  {t("drugAndFertilizersWarehouse")}{" "}
                  {stock && stock.medicalStock > 0 ? (
                    <ExclamationCircleOutlined />
                  ) : (
                    ""
                  )}
                </span>
              </Link>
            </Menu.Item>
          )}
          {perms.warehouse.subs.productsWarehouse.perms.read && (
            <Menu.Item key="36">
              <Link to={`/warehouse/products`}>
                <span className={stock && stock.cropStock > 0 ? "red" : ""}>
                  {t("productWarehouse")}{" "}
                  {stock && stock.cropStock > 0 ? (
                    <ExclamationCircleOutlined />
                  ) : (
                    ""
                  )}
                </span>
              </Link>
            </Menu.Item>
          )}
          {perms.warehouse.subs.reservesWarehouse.perms.read && (
            <Menu.Item key="37">
              <Link to={`/warehouse/reserves`}>
                <span className={stock && stock.reserveStock > 0 ? "red" : ""}>
                  {t("reserveWarehouse")}{" "}
                  {stock && stock.reserveStock > 0 ? (
                    <ExclamationCircleOutlined />
                  ) : (
                    ""
                  )}
                </span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="37.5">
            <Link to={`/warehouse/fuel-consumption`}>
              <span>Yanacaq sərfiyatı</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="38">
            <Link to={`/warehouse/silo`}>
              <span>Silolar</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {perms.workplan.perms.read && (
        <SubMenu
          key="41"
          title={
            <span>
              <PicCenterOutlined />
              <span>{t("businessPlanning")}</span>
            </span>
          }
        >
          {perms.workplan.subs.annual.perms.read && (
            <Menu.Item key="42">
              <Link to={`/work-plan/annual`}>
                <span>{t("yearly")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.workplan.subs.daily.perms.read && (
            <Menu.Item key="43">
              <Link to={`/work-plan/daily`}>
                <span>{t("daily")}</span>
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="44">
            <Link to="/statistics">
              <span>Statistika</span>
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {perms.financeAdminstrator.perms.read && (
        <SubMenu
          key="51"
          title={
            <span>
              <UpCircleOutlined />
              <span>{t("financeAdmin")}</span>
            </span>
          }
        >
          {perms.financeAdminstrator.subs.operationTypes.perms.read && (
            <Menu.Item key="52">
              <Link to={`/finance/operation-types`}>
                <span>{t("operationTypes")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.paymentTypes.perms.read && (
            <Menu.Item key="53">
              <Link to={`/finance/payment-types`}>
                <span>{t("paymentTypes")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.accountTypes.perms.read && (
            <Menu.Item key="54">
              <Link to={`/finance/account-types`}>
                <span>{t("accountTypes")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.areaNames.perms.read && (
            <Menu.Item key="55">
              <Link to={`/finance/area-names`}>
                <span>{t("areaNames")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.customers.perms.read && (
            <Menu.Item key="56">
              <Link to={`/finance/customers`}>
                <span>{t("customers")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.operationPoints.perms.read && (
            <Menu.Item key="57">
              <Link to={`/finance/operation-points`}>
                <span>{t("operationPoints")}</span>
              </Link>
            </Menu.Item>
          )}
          {perms.financeAdminstrator.subs.sectors.perms.read && (
            <Menu.Item key="58">
              <Link to={`/finance/sectors`}>
                <span>{t("sectors")}</span>
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )} */}
      <SubMenu
        key="81"
        title={
          <span>
            <AuditOutlined />
            <span>{t("admin")}</span>
          </span>
        }
      >
       
        <Menu.Item key="83">
          <Link to={`/news/categories`}>
            <span  className="alt-menu-title" >Xəbər kateqoriyaları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="84">
          <Link to={`/activity/type`}>
            <span  className="alt-menu-title" >Fəaliyyət növləri</span>
          </Link>
        </Menu.Item>
        <SubMenu
          key="85"
          title={
            <span>
              <span style={{color:'black'}}  className="alt-menu-title">Paketlər</span>
            </span>
          }
        >
          <Menu.Item key="85.1">
            <Link to={`/individual-registration`}>
              <span   className="alt-menu-title">Fərdi Üzvlük Paketi</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="85.2">
            <Link to={`/corporative-registration`}>
              <span  className="alt-menu-title" >Korporativ Üzvlük Paketi</span>
            </Link>
          </Menu.Item>

        </SubMenu>
        <Menu.Item key="86">
          <Link to={`/reasonofreject`}>
            <span   className="alt-menu-title">İmtina səbəbləri</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="87">
          <Link to={`/admin/users`}>
            <span  className="alt-menu-title" >İstifadəçilər</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="88">
          <Link to={`/admin/branch`}>
            <span   className="alt-menu-title">Sahələr</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="89">
          <Link to={`/admin/media-agency`}>
            <span   className="alt-menu-title">Media Agentliyi</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="11"
        title={
          <span>
            <HomeOutlined />
            <span>Haqqımızda</span>
          </span>
        }
      >
        <Menu.Item key="12">
          <Link to={`/history`}>
            <span  className="alt-menu-title">Tarixçəmiz</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to={`/philosophy`}>
            <span  className="alt-menu-title">Fəlsəfəmiz</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to={`/ethical-rules`}>
            <span  className="alt-menu-title">Etik qaydalar</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to={`/strategic-partnership`}>
            <span  className="alt-menu-title">Strateji tərəfdaşlıq</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link to={`/privacy-policy`}>
            <span  className="alt-menu-title">Gizlilik siyasəti</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="17">
          <Link to={`/managment`}>
            <span  className="alt-menu-title">İdarəetmə</span>
          </Link>
        </Menu.Item>

      </SubMenu>
      <SubMenu
        key="21"
        title={
          <span>
            <FundProjectionScreenOutlined />
            <span>Fəaliyyət</span>
          </span>
        }
      >
        <Menu.Item key="22">
          <Link to={`/advantages`}>
            <span  className="alt-menu-title">Üstünlüklərimiz</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="23">
          <Link to={`/services`}>
            <span  className="alt-menu-title">Xidmətlərimiz</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="24">
          <Link to={`/honorary-person`}>
            <span  className="alt-menu-title">Şəxsiyyətlər</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="25">
          <Link to={`/memorandums`}>
            <span  className="alt-menu-title">Memorandumlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="101"
        title={
          <span>
            <DropboxOutlined  />
            <span>Akreditasiya</span>
          </span>
        }
      >
        <Menu.Item key="102">
          <Link to={`/startup`}>
            <span  className="alt-menu-title">Startuplar</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="91"
        title={
          <span>
            <PictureOutlined />
            <span>Multimedia</span>
          </span>
        }
      >
        <Menu.Item key="92">
          <Link to={`/news`}>
            <span  className="alt-menu-title">Xəbərlər</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu
        key="61"
        title={
          <span>
            <FileDoneOutlined />
            <span >Müraciətlər</span>
          </span>
        }
      >
          <Menu.Item key="62">
            <Link to={`/members/individual-members`}>
              <span   className="alt-menu-title">Bütün müraciətlər</span>
            </Link>
          </Menu.Item>
      </SubMenu>
      <SubMenu
        key="31"
        title={
          <span>
            <ContactsOutlined />
            <span>Əlaqə</span>
          </span>
        }
      >
        <Menu.Item key="32">
          <Link to={`/contact`}>
            <span  className="alt-menu-title">Əlaqə məlumatları</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="33">
          <Link to={`/message`}>
            <span  className="alt-menu-title">Mesajlar</span>
          </Link>
        </Menu.Item>
      </SubMenu>
      {/* {perms.dailyFinancialReports.perms.read && (
          <Menu.Item key="1">
            <Link to={`/daily-financial-reports`}>
              <QuestionCircleOutlined />
              <span>{t("dailyFinancialReports")}</span>
            </Link>
          </Menu.Item>
      )} */}
    </Menu>
  );
};

const mapStateToProps = ({ user, stock }) => {
  return {
    // perms: user.data.userPermissions, stock 
  };
};

export default connect(mapStateToProps, {})(MenuList);


